











































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/types/tables';
import { searchAbillsUsers, searchAbillsUsersDownloadExcel } from '@/api';
import { Customer } from '@/types/customers';
import { saveAs } from 'file-saver';

@Component({name: 'SearchAbillsUsersList'})
export default class SearchAbillsUsersList extends Vue {
    search = '';
    headers: Header[] = [
        {text: '#', value: 'index'},
        {text: 'Логин', value: 'id'},
        {text: 'ФИО', value: 'fio'},
        {text: 'Улица', value: 'address_street'},
        {text: 'Дом', value: 'address_build'},
        {text: 'Квартира', value: 'address_flat'},
        {text: 'Тел', value: 'phone'},
        {text: 'Мобильный', value: '_Mobile'},
        {text: 'Депозит', value: 'deposit'},
        {text: 'Пароль', value: 'pass'},
        {text: 'Тариф', value: 'tarif_name'},
        {text: 'Свич', value: 'switch_ip'},
        {text: 'Порт', value: 'port'},
        {text: 'Допуслуги', value: 'services'},
    ];
    // TODO type!
    users = [];
    loading = false;
    excelLoading = false;

    async downloadExcel() {
        if (this.search !== '') {
            this.excelLoading = true;
            try {
                const blob = await searchAbillsUsersDownloadExcel(this.search);
                saveAs(blob, this.search + '.xlsx');
            } catch (e) {
            } finally {
                this.excelLoading = false;
            }
        }
    }

    async loadSearch() {
        if (this.search !== '') {
            this.loading = true;

            try {
                const users = await searchAbillsUsers(this.search);
                this.users = users.customers.map(
                    (items: any, index: number) => ({
                        ...items,
                        index: index + 1,
                    }));
            } catch (e) {
            } finally {
                this.loading = false;
            }
        }
    }
}
